
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from "react-i18next";
function Customers(){ 
    const settings = {
        autoplay : true,
        dots: false,
        infinite: true,
        arrows: false,
        speed: 900,
        slidesToShow: 5,
        responsive: [
         {
           breakpoint: 768, // Screen width less than 768px (mobile)
           settings: {
             slidesToShow: 3, // Show 3 slides on mobile
           },
         },
         {
           breakpoint: 1024, // Screen width less than 1024px (tablet or smaller)
           settings: {
             slidesToShow: 4, // Example for tablet (optional)
           },
         },
       ],
      };
      const {t, i18n} = useTranslation();


    
    return(
        
        <div className='px-4 py-16 lg:p-16'>
        <div>
          <p className="text-3xl text-secondary font-semibold leading-relaxed">{t("customers-title")}</p>
          <p className="text-gray">{t("customers-subtitle")}</p>
        </div>
        <div>
            <Slider {...settings} className='z-5 my-12'>
                        <div className='my-6'>
                           <img  src='images/customers/customer1.png' title="logo1"  className='w-20 h-10 md:w-40 md:h-20 mx-6'/>
                        </div>
                        <div className="item" >
                           <img  src='images/customers/customer2.png' title="logo2"  className='w-12 h-20 md:w-48 md:h-36 mx-6'/>
                                
                        </div>
                        <div className="my-4" >
                           <img  src='images/customers/customer3.png' title="logo3" className='h-14 md:h-28 mx-6'/>
                                
                        </div>
                        <div className="item" >
                           <img  src='images/customers/customer4.png' title="logo4"  className='h-16 md:h-36 mx-6'/>
                                
                        </div>
                        <div className="item" >
                           <img  src='images/customers/customer5.png' title="logo5"  className='h-16 md:h-36 mx-6'/>
                                
                        </div>
                        <div className="item" >
                           <img  src='images/customers/customer6.png' title="logo6" className='h-16 md:h-36 mx-6'/>
                                
                        </div>
                        <div className="item" >
                           <img  src='images/customers/customer7.png' title="logo7" className='w-20 h-10 md:w-40 md:h-28 mx-6'/>
                                
                        </div>
                      
                    </Slider>
        </div>
        </div>

    )
}

export default Customers;