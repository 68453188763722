import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      "website-title": "Mithaq Al Tachrie",
      "home": "Home",
      "about-us": "About us",
      "our-services" : "Our Services",
      "blog": 'Blog',
      "contact-us": "Contact us",
      "schedule-free-consultation": "Schdule Free Consultation",
      "experienced-lawyers": "Experienced Lawyers,",
      "proven": "Proven",
      "results": "Results",
      "our-expertise": "Our Expertise",
      "learn-more-about": "Learn More About",
      "firm-journey-values": "Our Firm's Journey and Values",
      "learn-more": "Learn More",
      "our-expertise-2" : "Our Expertise :",
      "solutions-tailored": "Solutions Tailored to Your Legal Needs",
      "bankruptcy": "Bankruptcy",
      "financial-claims-title": "Financial Claims",
      "commercial-arbitration-title": "Commercial Arbitration",
      "drafting-and-reviewing": "Drafting and Reviewing",
      "debt-collection": "Debt Collection",
      "civil-cases": "Civil Cases",
      "legal-risk-analysis" : "Legal Risk Analysis",
      "memorandums-and-regulations": "Writing Memorandums and Regulations",
      "documentation": "Documentation",
      "legal-consultations": "Legal Consultations",
      "corporate-governance": "Corporate Governance",
      "more-services": "Check more services",
      "meet-our-team": "Meet Our Team",
      "see-more": "See More",
      "mustapha-ahmed": "Mustapha Ahmed",
      "lawyer": "Lawyer",
      "case-studies": "Case Studies",
      "check-our-blog": "Check Our Blog",
      "Free Consulation": "Free consultation",
      "Name":"Name",
      "Email":"Email",
      "Phone":"Phone",
      "Case Description":"Case Description",
      "Pick Case Area":"Pick Case Area",
      "Send": "Send",
      "Stay updated to the":"Stay updated to the",
      "Newest":"Newest",
      "legal":"legal",
      "information":"information",
      "Mithaq Al Tashrie: Article collection":"Mithaq Al Tashrie: Article collection",
      "Pick a writer you want to read to":"Pick a writer you want to read to",
      "Search an article":"Search an article",
      "Business Dispute Resolution": "Business Dispute Resolution",
      "Sarah Lee" : "Sarah Lee",
      "free-consulation": "Free Consulation",
      "name": "Name",
      "email": "Email",
      "phone": "Phone",
      "pick-case-area": "Pick Case Area",
      "area": "Area",
      "case-description": "Case Description",
      "send": "Send",
      "st-name": "3795 Prince Abdulaziz Ibn Musaid Ibn Jalawi St, RHOA7514",
      "eriadh-ksa": "Eriyadh 12221، Saudi Arabia",
      "mon-fri": "Mon-Fri",
      "closed-holiday": "Closed for Public Holiday",
      "copyright-2024": "Copyright 2024",
      "rights-reserved": "All Rights Reserved",
      "join-newsletter": "Join our newsletter to get our latest news",
      "join": "Join",
      "Quick Links": "Quick Links",
      "Recent News": "Recent News",
      "Article Title": "Article Title",
      "This is our Result": "This is our Result",
      "How capable we are at work": "How capable we are at work",
      "shines through in every endeavor.": "shines through in every endeavor.",
      "Happy clients": "Happy clients",
      "Won cases": "Won cases",
      "Years of experience": "Years of experience",
      "Legal experts": "Legal experts",
      "Meet Our CEO": "Meet Our CEO",
      "Check Our Services": "Check Our Services",
      "Real estate cases": "Real estate cases",
      "Expert Legal Solutions for Your Property Disputes": "Expert Legal Solutions for Your Property Disputes",
      "service-paragraph-1":"Real estate transactions and ownership in Tunisia are governed by a complex web of laws and regulations.  Whether you're a seasoned investor, a first-time homebuyer, or a business owner with commercial property interests, understanding your legal rights and obligations is crucial to protect your valuable assets. Our team of experienced real estate lawyers is well-versed in Tunisian property law and dedicated to providing you with the comprehensive legal support you need to navigate any real estate challenge.",
      "service-paragraph-2":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse",
      "What will you get": "What will you get?",
      "How does it work": "How does it work?",
      "Comprehensive Legal Counse": "Comprehensive Legal Counse",
      "Dispute Resolution": "Dispute Resolution",
      "Document Drafting and Review": "Document Drafting and Review",
      "Litigation and Representation": "Litigation and Representation",
      "Our Vision for the Future": "Our Vision for the Future",
      "We are a team with a variety": "We are a team with a variety of skills, each member contributes their expertise, and works professionally among members and clients.",
      "Leadership": "Leadership",
      "To be a top-tier law firm in Saudi Arabia": "To be a top-tier law firm in Saudi Arabia",
      "Destination for Excellence": "Destination for Excellence",
      "Known for accurate": "Known for accurate, effective legal services",
      "Experience & Knowledge": "Experience & Knowledge",
      "A team with deep experience and expertise": "A team with deep experience and expertise",
      "Innovation": "Innovation",
      "Leading the way with innovative legal solutions" : "Leading the way with innovative legal solutions",
      "Testimonials": "Testimonials",
      "What Our Clients Are Saying?": "What Our Clients Are Saying?",
      "solutions-tailored-1": "Solutions Tailored",
      "legal-needs": "to Your Legal Needs",
      "Download File" : "Download File",
      "message-success" : "Message Sent Successfully",
      "about-description" : "Our law firm, based in Saudi Arabia, is dedicated to providing exceptional legal services. With a team of highly skilled professionals, we are committed to offering personalized solutions that meet the unique needs of our clients. Our expertise spans across various legal domains, ensuring comprehensive support and representation.",
      "about-description-2": "At our law firm, we believe in building long-lasting relationships with our clients.",
      "our-team-description" : "Our team consists of experienced lawyers and legal professionals who are dedicated to delivering top-notch legal services. Each member brings a wealth of knowledge and expertise, ensuring that our clients receive the best possible representation and advice.",
      "ceo-description" : "Mr. Walid Jaza Al-Anzi, our esteemed CEO, is a distinguished lawyer with years of experience in the legal field. His leadership and vision have been instrumental in driving the success and growth of our firm. Under his guidance, we have established a reputation for excellence and integrity.",
      "ceo-description-2": "Mr. Walid Jaza Al-Anzi is not only a leader but also a mentor to the team. His approach to law is both innovative and client-focused, ensuring that the firm stays ahead in the ever-evolving legal landscape. With numerous accolades and a deep understanding of the legal system in Saudi Arabia, Mr. Walid is dedicated to upholding the highest standards of legal practice.",
      "free-consultation-desc" : "Contact us today for a free consultation and let us help you with your legal needs.",
      "testimonial-1": "The legal team at this firm provided me with outstanding support and guidance. I highly recommend their services.",
      "testimonial-1-writer" : "Ahmed Al-Fahad",
      "testimonial-1-pos" : "CEO, Al-Fahad Enterprises",
      "testimonial-2": "Professional, efficient, and trustworthy. They handled my case with utmost care and dedication.",
      "testimonial-2-writer" : "Sara Al-Mutairi",
      "testimonial-2-pos" : "Legal Advisor, Gulf Financial Services",
      "testimonial-3": "I am grateful for the exceptional legal assistance I received. Their expertise and commitment are truly commendable.",
      "testimonial-3-writer" : "Khalid Al-Harabi",
      "testimonial-3-pos" : "General Manager, Al-Harabi Trading",
      "message error":"message can not be empty",
      "email error":"email can not be empty",
      "phone error": "phone number must be valid",
      "zone error":"please enter a valid zone code",
      "search" : "search",
      "financial-claim-subtitle": "Our Financial Claim Service ensures swift recovery of lost or owed funds with expert legal assistance.",
      "finantial-claim-description":"Our Financial Claim Service helps clients recover lost or owed funds efficiently. We specialize in navigating complex legal and financial processes to ensure swift and successful claims. Our experienced legal team provides personalized assistance tailored to your unique situation. Trust us to handle your case with professionalism and expertise. Contact us today for a consultation.",
      "bankruptcy-subtitle":"Our Bankruptcy Service provides expert legal guidance to help you navigate financial challenges and achieve a fresh start.",
      "bankruptcy-description": "Our Bankruptcy Service is dedicated to guiding clients through the complexities of financial distress. We offer comprehensive legal support to help you understand your options, protect your assets, and achieve a fresh start. Our experienced legal team works closely with you to develop a personalized strategy tailored to your unique financial situation. Whether you’re an individual or a business, we are committed to providing compassionate and effective representation throughout the bankruptcy process. Trust us to help you regain control of your financial future.",
      "commercial-arbitration-subtitle":"Our Commercial Arbitration Service ensures efficient, confidential resolution of business disputes with expert legal guidance.",
      "commercial-arbitration-description":"Our Commercial Arbitration Service offers expert legal guidance to resolve business disputes efficiently and confidentially. We specialize in handling complex commercial conflicts, providing a streamlined alternative to litigation. Our experienced arbitrators work closely with all parties to reach fair and enforceable agreements, ensuring that your business interests are protected. Whether you're facing a contractual dispute, partnership disagreement, or any other commercial issue, we are committed to delivering effective and impartial resolution services. Trust us to help you navigate the arbitration process with professionalism and expertise.",
      "darfting-subtitle":"Our Drafting and Reviewing Service provides precise legal documents and expert review to safeguard your business interests.",
      "dtafting-description":"Our Drafting and Reviewing Service is designed to ensure your legal documents are both accurate and effective in protecting your business interests. We specialize in crafting clear, comprehensive agreements tailored to your specific needs, whether it’s contracts, agreements, policies, or any other legal documentation. Our experienced legal team thoroughly reviews every detail to identify potential risks and ensure compliance with relevant laws and regulations. By working closely with you, we provide personalized solutions that align with your business goals. Trust us to handle your legal documents with the highest level of professionalism and care.",
      "debt-subtitle":"Our Debt Collection Service provides expert legal support to recover outstanding debts efficiently and professionally.",
      "debt-description":"Our Debt Collection Service is dedicated to helping businesses and individuals recover outstanding debts swiftly and effectively. We understand the challenges involved in debt recovery and offer comprehensive legal support tailored to your specific needs. Our experienced team works diligently to pursue your claims, employing proven strategies to ensure timely payment while maintaining professional relationships. We handle the entire process, from initial contact with the debtor to legal action if necessary, always with the goal of maximizing recovery while minimizing costs. Trust us to manage your debt collection with professionalism and expertise.",
      "civil-cases-subtitle":"Our Civil Case Service provides expert legal representation for efficiently resolving a broad range of civil disputes.",
      "civil-cases-description":"Our Civil Case Service is designed to offer thorough and effective legal representation for a diverse range of civil disputes. From property and contract disputes to personal injury claims, we guide you through each step of the legal process with expertise and dedication. Our skilled attorneys conduct detailed case evaluations, develop strategic approaches, and work diligently to protect your rights and achieve favorable outcomes. We prioritize clear communication and personalized support, ensuring you are well-informed and confident throughout your case. Rely on us to handle your civil matters with professionalism, precision, and commitment.",
      "risk-analysis-subtitle":"Our Legal Risk Analysis Service offers expert assessments to identify and mitigate potential legal risks, ensuring informed decision-making and protection.",
      "risk-analysis-description":"Our Legal Risk Analysis Service provides detailed assessments to identify and mitigate potential legal risks affecting your business or personal affairs. We conduct comprehensive reviews of contracts, operations, and legal environments to uncover vulnerabilities and recommend strategic solutions. Our experienced team delivers clear, actionable insights to help you make informed decisions and safeguard against potential legal issues. With a focus on proactive risk management, we ensure you are well-prepared to navigate complex legal landscapes effectively. Trust us to provide expert analysis and support to protect your interests.",
      "memorandums-subtitle":"Our Memorandums and Regulations Service provides precise drafting and review of legal documents, ensuring compliance and clarity in your organizational policies.",
      "memorandums-description":"Our Memorandums and Regulations Service specializes in crafting and reviewing legal documents to ensure accuracy, compliance, and clarity. We offer expert assistance in drafting detailed memorandums and comprehensive regulations tailored to your organization's needs. Our experienced team meticulously reviews existing documents to identify any gaps or inconsistencies, providing clear and actionable recommendations for improvement. Whether you’re establishing new policies or updating existing ones, we ensure that all documents adhere to relevant laws and regulations. Trust us to deliver professional, well-structured documents that support effective governance and operational excellence.",
      "doc_sub":"Our Documentation Service ensures accurate, compliant, and effective preparation and review of all legal and business documents.",
      "doc-desc":"Our Documentation Service offers meticulous preparation and review of legal and business documents to ensure accuracy, compliance, and effectiveness. We handle a wide range of documents, including contracts, agreements, and reports, tailored to meet your specific needs. Our experienced team ensures that all documents are clear, comprehensive, and aligned with legal standards. We provide personalized support to address any concerns and make necessary adjustments. Trust us to manage your documentation with the utmost professionalism, delivering well-crafted documents that support your objectives and safeguard your interests.",
      "consultation-subtitle":"Our Legal Consultation Service provides expert advice and strategic guidance to help you navigate complex legal matters with confidence and clarity.",
      "consultation-description":"Our Legal Consultation Service offers comprehensive advice and strategic guidance tailored to your specific legal needs. Whether you’re facing a complex legal issue, need assistance with compliance, or require support in making informed decisions, our experienced attorneys are here to help. We provide in-depth analysis and practical solutions, ensuring you understand your options and potential outcomes. Our goal is to empower you with the knowledge and confidence needed to navigate the legal landscape effectively. Trust us to deliver personalized, expert consultation that supports your objectives and protects your interests.",
      "corporate-governance-subtitle":"Our Corporate Governance Service ensures effective governance practices, compliance, and transparency to enhance organizational integrity and performance.",
      "corporate-governance-description":"Our Corporate Governance Service provides expert guidance to help you establish and maintain effective governance practices that ensure compliance, transparency, and accountability within your organization. We assist with developing robust governance frameworks, policies, and procedures tailored to your business needs. Our experienced team offers strategic advice on board structure, risk management, and regulatory requirements to support sound decision-making and uphold best practices. Trust us to enhance your corporate governance and strengthen your organization’s integrity and performance.",
      "cases-subtitle" : "Discover legal insights and updates in our informative blog",
      "articles-you-might-like": "Articles you might like as well",
      "Lawyer": "Lawyer",
      "Add new Comment": "Add new Comment",
      "your name": "Your Name",
      "Written by" : "Written by",
      "ContactInfoTime" : "SUN-THU 08:00 - 17:00  KSA",
      "ContactInfoClosed" : "(Closed for Public Holiday)",
      "Whatsapp": "Whatsapp",
      "customers-title" :"Our prominent customers",
      "customers-subtitle":"Prominent customers include leading corporations and organizations across various industries, known for their innovation and market influence.",
      "choose-category": "Choose the category",
      "&laquo; Previous" : "Previous",
      "Next &raquo;" :"Next",
      "member1" :"Walid Jaza Al-Anzi",
      "work1":"CEO",
      "member2" :"Abdullah Mohammed Dawood",
      "work2":"Consultant",
      "member3" :"Saeed  Saad Al Zahrani",
      "work3":"Lawyer",
      "member4" :"Eid Habilis Al Otaibi",
      "work4":"Lawyer",
      "member5" :"Rashid Awad Al-Otaibi",
      "work5":"Lawyer",
      "member6" :"Abdul Aziz Abdullah Al-Mousa",
      "work6":"Lawyer",
      "member7" :"Mazen Omar Bahakim",
      "work7":"Juristic secretary",
      "see-more": "See more",
      "Category List":"Category List"
     
    }
  },
  ar: {
    translation: {
      "website-title":"ميثاق التشريع",
      "home": "الصفحة الرئيسية",
      "about-us": "نبذة عنّا",
      "our-services": "خدماتنا",
      "blog": "المدوّنة",
      "contact-us": "اتصل بنا",
      "schedule-free-consultation": "حجز استشارة مجانية",
      "experienced-lawyers": "محامون ذوو خبرة",
      "proven": "نتائج",
      "results": "مثبتة",
      "our-expertise": "خبرتنا",
      "learn-more-about": " تعرّف أكثر على مسيرتنا و قيمنا",
      "firm-journey-values": "", 
      "our-expertise-2" : "خبرتنا :",
      "solutions-tailored": "حلول مُصممة خصيصًا لتلبية احتياجاتك القانونية",
      "bankruptcy": "الإعسار المالي",
      "financial-claims-title": "قضايا النزاعات المالية",
      "commercial-arbitration-title": "التحكيم التجاري",
      "drafting-and-reviewing": "صياغة ومراجعة العقود",
      "debt-collection": "تحصيل الديون",
      "civil-cases": "قضايا مدنية",
      "legal-risk-analysis" : "تحديد وتقييم المخاطر القانونية",
      "memorandums-and-regulations": "كتابة المذكرات واللوائح",
      "documentation": "تنظيم وإدارة الوثائق",
      "legal-consultations": "استشارات قانونية",
      "corporate-governance": "حوكمة الشركات",
      "more-services": "المزيد من الخدمات",
      "meet-our-team": "كوادرنا القانونية المتميزة",
      "see-more": "المزيد",
      "mustapha-ahmed": "مصطفى أحمد",
      "lawyer": "محامي",
      "case-studies": "المدونة",
      "check-our-blog": "اطلع على مدونتنا",
      "Free Consulation": "طلب إستشارة",
      "Name":"الإسم",
      "Email":"البريد الإلكتروني",
      "Phone":"الهاتف",
      "Case Description":"وصف القضية",
      "Pick Case Area":"اختيار المجال",
      "Send":"إرسال",
      "Stay updated to the":"ابق على ",
      "Newest":"اطلاع",
      "legal":"دائم",
      "information":"على آخر المستجدات القانونية",
      "Mithaq Al Tashrie: Article collection":"ميثاق التشريع : مجموعة المدونات",
      "Pick a writer you want to read to":"اختر كاتب",
      "Search an article": "ابحث عن مقال",
      "Business Dispute Resolution": "حل النزاعات التجارية",
      "Sarah Lee" : "سارة ليي",
      "free-consulation": "استشارة مجانية",
      "name": "الإسم",
      "email": "البريد الالكتروني",
      "phone": "الهاتف",
      "pick-case-area": "اختر ميدان القضية",
      "area": "ميدان",
      "case-description": "وصف القضيّة",
      "send": "ارسال",
      "st-name": "3795 شارع الأمير عبدالعزيز بن مساعد بن جلوي، RHOA7514، 7514، ",
      "eriadh-ksa": "الرياض 12221، المملكة العربية السعودية",
      "mon-fri": "الاثنين - الجمعة",
      "closed-holiday": "مغلق للعطلة الوطنية",
      "copyright-2024": "حقوق الطبع والنشر 2024",
      "rights-reserved": "جميع الحقوق محفوظة",
      "join-newsletter": "انضم إلى قائمة البريد الإلكتروني الخاصة بنا لتبقى على اطلاع بآخر المستجدات",
      "join": "انضم",
      "Quick Links": "روابط سريعة",
      "Recent News": "مستجدات",
      "Article Title": "المقال",
      "This is our Result": "هذه هي نتائجنا",
      "How capable we are at work": "تظهر قدرتنا على العمل في كل مسعى",
      "shines through in every endeavor.": "",
      "Happy clients": "عملاء سعداء",
      "Won cases": " قضايا تم كسبها",
      "Years of experience": "سنوات من الخبرة",
      "Legal experts": " خبراء قانونيون",
      "Meet Our CEO": "تعرّف على قيادة مكتبنا",
      "Check Our Services": "تعرّف على خدماتنا",
      "Real estate cases": "قضايا عقارية",
      "Expert Legal Solutions for Your Property Disputes": "حلول قانونية متخصصة لنزاعاتكم العقارية",
      "service-paragraph-1":"تخضع معاملات العقارات والملكية في تونس لشبكة معقدة من القوانين واللوائح. سواء كنت مستثمرًا متمرسًا أو مشتريًا لأول مرة أو صاحب عمل لديه مصالح عقارية تجارية، فإن فهم حقوقك والتزاماتك القانونية أمر بالغ الأهمية لحماية أصولك القيمة. يتمتع فريقنا من محامي العقارات ذوي الخبرة بمعرفة واسعة بقانون الملكية التونسي ومكرس لتزويدك بالدعم القانوني الشامل الذي تحتاجه للتعامل مع أي تحدٍ عقاري.",
      "service-paragraph-2":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse",
      "What will you get": "ما الذي ستحصل عليه",
      "How does it work": "كيف تعمل خدماتنا؟",
      "Comprehensive Legal Counse": "استشارات قانونية شاملة",
      "Dispute Resolution": "حل النزاعات العقارية",
      "Document Drafting and Review": " صياغة ومراجعة العقود والوثائق القانونية",
      "Litigation and Representation": " تقاضي وتمثيل قانوني",
      "Our Vision for the Future": "تطلعاتنا للمستقبل",
      "We are a team with a variety": "نحن فريق يمتلك مجموعة متنوعة من المهارات، حيث يساهم كل عضو بخبرته، ونعمل بمهنية واحترافية فيما بيننا ومع عملائنا",
      "Leadership": "الريادة",
      "To be a top-tier law firm in Saudi Arabia": "أن نكون شركة محاماة من الطراز الأول في المملكة العربية السعودية",
      "Destination for Excellence": "وجهة التميز",
      "Known for accurate": "معروفون بتقديم خدمات قانونية دقيقة وفعالة",
      "Experience & Knowledge": "الخبرة والمعرفة",
      "A team with deep experience and expertise": "فريق يتمتع بخبرة ومعرفة عميقة",
      "Innovation": "الابتكار",
      "Leading the way with innovative legal solutions" : "روّاد في تقديم حلول قانونية مبتكرة",
      "Testimonials": "تجارب عملائنا",
      "What Our Clients Are Saying?": "ماذا يقولون؟",
      "solutions-tailored-1": "حلول مُصممة خصيصًا",
      "legal-needs": "لتلبية احتياجاتك القانونية",
      "Download File" : "تحميل الملف",
      "message-success" : "تمّ الإرسال بنجاح",
      "about-description" : "مكتبنا للمحاماة ملتزم بتقديم خدمات قانونية استثنائية. مع فريق من المهنيين ذوي الكفاءة العالية، نحن ملتزمون بتقديم حلول مخصصة تلبي الاحتياجات الفريدة لعملائنا. تشمل خبراتنا مجالات قانونية متعددة، مما يضمن دعماً وتمثيلاً شاملاً.",
      "about-description-2" : "في مكتب المحاماة لدينا، نؤمن ببناء علاقات طويلة الأمد مع عملائنا. نهجنا يعتمد على فهم تعقيدات كل قضية وتقديم حلول قانونية استراتيجية مصممة خصيصاً لتلبية الاحتياجات الفردية.",
      "our-team-description" : "فريقنا يتكون من محامين ومحترفين قانونيين ذوي خبرة ملتزمين بتقديم خدمات قانونية رفيعة المستوى. كل عضو يجلب معه ثروة من المعرفة والخبرة، مما يضمن أن عملائنا يحصلون على أفضل تمثيل ونصائح ممكنة.",
      "ceo-description" : "السيد وليد جزاء العنزي، الرئيس التنفيذي المحترم لدينا، هو محامٍ بارز يتمتع بسنوات من الخبرة في المجال القانوني. لقد كان لقيادته ورؤيته دورٌ أساسي في تحقيق نجاح ونمو مكتبنا. تحت إدارته، قمنا ببناء سمعة ممتازة للنزاهة والتفوق.",
      "ceo-description-2" : "السيد وليد جزاء العنزي ليس قائدًا فحسب، بل هو أيضًا مرشد للفريق. نهجه في القانون مبتكر ويركز على العملاء، مما يضمن أن المكتب يظل متقدماً في المشهد القانوني المتغير باستمرار. مع العديد من الجوائز وفهم عميق للنظام القانوني في المملكة العربية السعودية، يكرس السيد وليد نفسه للحفاظ على أعلى معايير الممارسة القانونية.",
      "free-consultation-desc" : "اتصل بنا اليوم للحصول على استشارة مجانية ودعنا نساعدك في احتياجاتك القانونية.",
      "testimonial-1": "قدم لي الفريق القانوني في هذا المكتب دعمًا وتوجيهًا ممتازين. أنصح بخدماتهم بشدة",
      "testimonial-1-writer" : "أحمد الفهد",
      "testimonial-1-pos" : "الرئيس التنفيذي، شركات الفهد",
      "testimonial-2": "مهنيون وفعالون ويمكن الوثوق بهم. تعاملوا مع قضيتي بأقصى درجات العناية والالتزام",
      "testimonial-2-writer" : "سارة المطيري",
      "testimonial-2-pos" : "مستشارة قانونية، خدمات الخليج المالية",
      "testimonial-3": "أنا ممتن للمساعدة القانونية الاستثنائية التي تلقيتها. خبرتهم والتزامهم جديرون بالثناء حقًا",
      "testimonial-3-writer" : "خالد الهرابي",
      "testimonial-3-pos" : "المدير العام، تجارة الحرابي",
      "message error":"الرسالة لا يمكن أن تكون فارغة",
      "email error":"البريد الإلكتروني لا يمكن أن يكون فارغا",
      "phone error": "الرجاء إعطاء رقم هاتف صحيح",
      "zone error":"الرجاء إعطاء رمز مكان صحيح",
      "search" : "بحث",
      "financial-claim-subtitle": "خدمتنا للمطالبات المالية تضمن استرداد الأموال المفقودة أو المستحقة بسرعة بمساعدة قانونية متخصصة",
      "finantial-claim-description":"خدمتنا لمطالبات المالية تساعد العملاء في استرداد الأموال المفقودة أو المستحقة بكفاءة. نحن متخصصون في التعامل مع الإجراءات القانونية والمالية المعقدة لضمان سرعة ونجاح المطالبات. فريقنا القانوني ذو الخبرة يقدم مساعدة مخصصة تناسب حالتك الفريدة. ثق بنا في التعامل مع قضيتك باحترافية وخبرة. تواصل معنا اليوم للحصول على استشارة.",
      "bankruptcy-subtitle":"خدمتنا للإفلاس تقدم إرشادات قانونية متخصصة لمساعدتك في تجاوز التحديات المالية والبدء من جديد.",
      "bankruptcy-description": "خدمتنا للإفلاس مكرسة لإرشاد العملاء خلال تعقيدات الضائقة المالية. نقدم دعمًا قانونيًا شاملاً لمساعدتك على فهم خياراتك، وحماية أصولك، وتحقيق بداية جديدة. يعمل فريقنا القانوني ذو الخبرة عن كثب معك لتطوير استراتيجية مخصصة تناسب وضعك المالي الفريد. سواء كنت فردًا أو شركة، نحن ملتزمون بتقديم تمثيل فعال ومتفهم خلال عملية الإفلاس. ثق بنا لمساعدتك في استعادة السيطرة على مستقبلك المالي.",
      "commercial-arbitration-subtitle":"خدمتنا للتحكيم التجاري تضمن حل النزاعات التجارية بكفاءة وسرية بمساعدة قانونية متخصصة.",
      "commercial-arbitration-description":"خدمتنا للتحكيم التجاري تقدم إرشادات قانونية متخصصة لحل النزاعات التجارية بكفاءة وسرية. نحن متخصصون في التعامل مع النزاعات التجارية المعقدة، مما يوفر بديلاً مبسطًا للتقاضي. يعمل محكّمونا ذوو الخبرة عن كثب مع جميع الأطراف للوصول إلى اتفاقات عادلة وقابلة للتنفيذ، مع ضمان حماية مصالح عملك. سواء كنت تواجه نزاعًا تعاقديًا أو خلافًا في الشراكة أو أي مسألة تجارية أخرى، نحن ملتزمون بتقديم خدمات حل النزاعات بفعالية وحيادية. ثق بنا لمساعدتك في اجتياز عملية التحكيم باحترافية وخبرة.",
      "darfting-subtitle":"خدمتنا لصياغة ومراجعة العقود توفر وثائق قانونية دقيقة ومراجعة متخصصة لحماية مصالحك التجارية.",
      "dtafting-description":"خدمتنا لصياغة ومراجعة العقود مصممة لضمان دقة وفعالية الوثائق القانونية في حماية مصالحك التجارية. نحن متخصصون في صياغة اتفاقيات واضحة وشاملة تتناسب مع احتياجاتك الخاصة، سواء كانت عقودًا أو اتفاقيات أو سياسات أو أي وثائق قانونية أخرى. يقوم فريقنا القانوني ذو الخبرة بمراجعة كل التفاصيل بدقة لتحديد المخاطر المحتملة وضمان الامتثال للقوانين واللوائح ذات الصلة. من خلال العمل عن كثب معك، نقدم حلولاً مخصصة تتماشى مع أهداف عملك. ثق بنا في التعامل مع وثائقك القانونية بأعلى مستوى من الاحترافية والعناية.",
      "debt-subtitle":"خدمتنا لتحصيل الديون تقدم دعمًا قانونيًا متخصصًا لاسترداد الديون المستحقة بكفاءة واحترافية.",
      "debt-description":"خدمتنا لتحصيل الديون مكرسة لمساعدة الشركات والأفراد في استرداد الديون المستحقة بسرعة وفعالية. نحن نفهم التحديات التي ينطوي عليها تحصيل الديون ونقدم دعمًا قانونيًا شاملاً يتناسب مع احتياجاتك الخاصة. يعمل فريقنا ذو الخبرة بجدية لمتابعة مطالباتك، معتمدين على استراتيجيات مجربة لضمان السداد في الوقت المناسب مع الحفاظ على العلاقات المهنية. نحن نتولى العملية بأكملها، بدءًا من الاتصال الأول بالمدين وصولاً إلى اتخاذ الإجراءات القانونية إذا لزم الأمر، مع التركيز دائمًا على تحقيق أقصى قدر من التحصيل مع تقليل التكاليف. ثق بنا لإدارة تحصيل ديونك باحترافية وخبرة.",
      "civil-cases-subtitle":"خدمتنا للقضايا المدنية تقدم تمثيلًا قانونيًا متخصصًا لحل مجموعة واسعة من النزاعات المدنية بكفاءة.",
      "civil-cases-description":"خدمتنا للقضايا المدنية تهدف إلى تقديم تمثيل قانوني شامل وفعّال لمجموعة متنوعة من النزاعات المدنية. من نزاعات الممتلكات والعقود إلى مطالبات الإصابات الشخصية، نحن نوجهك خلال كل مرحلة من مراحل العملية القانونية بخبرة وتفانٍ. يقوم محامونا المهرة بإجراء تقييمات دقيقة للقضايا، وتطوير استراتيجيات متكاملة، والعمل بجد لحماية حقوقك وتحقيق نتائج إيجابية. نحن نركز على التواصل الواضح والدعم الشخصي، لضمان أن تكون على دراية وثقة طوال القضية. اعتمد علينا في التعامل مع مسائل القضايا المدنية باحترافية ودقة والتزام.",
      "risk-analysis-subtitle":"خدمتنا لتحليل المخاطر القانونية تقدم تقييمات متخصصة لتحديد وتخفيف المخاطر القانونية المحتملة، لضمان اتخاذ قرارات مستنيرة وحماية مصالحك.",
      "risk-analysis-description":"خدمتنا لتحليل المخاطر القانونية تقدم تقييمات مفصلة لتحديد وتخفيف المخاطر القانونية المحتملة التي تؤثر على عملك أو شؤونك الشخصية. نقوم بمراجعات شاملة للعقود والعمليات والبيئات القانونية للكشف عن الثغرات وتقديم حلول استراتيجية. يوفر فريقنا ذو الخبرة رؤى واضحة وقابلة للتنفيذ لمساعدتك في اتخاذ قرارات مستنيرة وحماية نفسك من المشاكل القانونية المحتملة. مع التركيز على إدارة المخاطر الاستباقية، نضمن لك الاستعداد جيدًا للتعامل مع المشهد القانوني المعقد بفعالية. ثق بنا لتقديم التحليل والخدمات المتخصصة لحماية مصالحك.",
      "memorandums-subtitle":"خدمتنا لصياغة اللوائح والمذكرات توفر صياغة ومراجعة دقيقة للوثائق القانونية، لضمان الامتثال والوضوح في سياساتك التنظيمية.",
      "memorandums-description":"خدمتنا لصياغة اللوائح والمذكرات تتخصص في إعداد ومراجعة الوثائق القانونية لضمان الدقة والامتثال والوضوح. نقدم مساعدة متخصصة في صياغة مذكرات مفصلة ولوائح شاملة تتناسب مع احتياجات منظمتك. يقوم فريقنا ذو الخبرة بمراجعة الوثائق الحالية بدقة لتحديد أي ثغرات أو تناقضات، ويقدم توصيات واضحة وقابلة للتنفيذ للتحسين. سواء كنت بصدد وضع سياسات جديدة أو تحديث السياسات الحالية، نضمن أن جميع الوثائق تتوافق مع القوانين واللوائح ذات الصلة. ثق بنا لتقديم مستندات مهنية ومنظمة تدعم الحوكمة الفعالة والتفوق التشغيلي.",
      "doc_sub":"خدمتنا لإعداد الوثائق تضمن تحضير ومراجعة دقيقة ومتوافقة وفعّالة لجميع الوثائق القانونية والتجارية.",
      "doc-desc":"خدمتنا لإعداد الوثائق تقدم تحضيرًا ومراجعة دقيقة للوثائق القانونية والتجارية لضمان الدقة والامتثال والفعالية. نتعامل مع مجموعة واسعة من الوثائق، بما في ذلك العقود والاتفاقيات والتقارير، لتلبية احتياجاتك الخاصة. يضمن فريقنا ذو الخبرة أن تكون جميع الوثائق واضحة وشاملة ومتوافقة مع المعايير القانونية. نقدم دعمًا شخصيًا لمعالجة أي مخاوف وإجراء التعديلات اللازمة. ثق بنا في إدارة وثائقك بأقصى درجات الاحترافية، وتقديم مستندات مُعدة بعناية تدعم أهدافك وتحمي مصالحك.",
      "consultation-subtitle":"خدمتنا للاستشارات القانونية تقدم مشورة خبيرة وتوجيهًا استراتيجيًا لمساعدتك في التعامل مع المسائل القانونية المعقدة بثقة ووضوح.",
      "consultation-description":"خدمتنا للاستشارات القانونية تقدم مشورة شاملة وتوجيهًا استراتيجيًا يتناسب مع احتياجاتك القانونية الخاصة. سواء كنت تواجه مسألة قانونية معقدة، تحتاج إلى مساعدة في الامتثال، أو تحتاج إلى دعم في اتخاذ قرارات مستنيرة، فإن محامينا ذوي الخبرة هنا لمساعدتك. نقدم تحليلًا عميقًا وحلولًا عملية، مع ضمان فهمك لخياراتك والنتائج المحتملة. هدفنا هو تمكينك بالمعرفة والثقة اللازمة للتعامل بفعالية مع المشهد القانوني. ثق بنا لتقديم استشارات متخصصة وشخصية تدعم أهدافك وتحمي مصالحك.",
      "corporate-governance-subtitle":"خدمتنا للحوكمة المؤسسية تضمن ممارسات حوكمة فعالة، والامتثال، والشفافية لتعزيز نزاهة وأداء المنظمة.",
      "corporate-governance-description":"خدمتنا للحوكمة المؤسسية تقدم إرشادات متخصصة لمساعدتك في إنشاء والحفاظ على ممارسات حوكمة فعالة تضمن الامتثال والشفافية والمساءلة داخل منظمتك. نساعد في تطوير أطر حوكمة قوية، وسياسات، وإجراءات تتناسب مع احتياجات عملك. يقدم فريقنا ذو الخبرة نصائح استراتيجية حول هيكل مجلس الإدارة، إدارة المخاطر، ومتطلبات اللوائح لدعم اتخاذ قرارات سليمة والحفاظ على أفضل الممارسات. ثق بنا لتعزيز حوكمتك المؤسسية وتقوية نزاهة وأداء منظمتك.",
      "cases-subtitle" : "اكتشف المستجدات والتحليلات القانونية في مدونتنا",
      "articles-you-might-like": "مقالات قد تعجبك",
      "Lawyer": "محامي",
      "Add new Comment": "أضف تعليق جديد",
      "your name": "الإسم",
      "Written by" : "الكاتب",
      "ContactInfoTime" : "08:00 - 17:00 الأحد-الخميس",
      "ContactInfoClosed" : "مغلق في العطل و الأعياد",
      "Whatsapp" : "واتس آب",
      "customers-title" :"أبرز عملائنا",
      "customers-subtitle":"تشمل العملاء البارزين الشركات الرائدة والمنظمات في مختلف الصناعات، المعروفة بابتكاراتها وتأثيرها في السوق.",
      "choose-category": "اختر الصنف الذي ترغب فيه",
      "&laquo; Previous" : "السابق",
      "Next &raquo;" :"التالي",
      "member1" :"وليد جزاء العنزي",
      "work1":"الرئيس التنفيذي",
      "member2" :"عبدالله محمد داود",
      "work2":"مستشار",
      "member3" :"سعيد سعد الزهراني",
      "work3":"محام",
      "member4" :"عيد حبيليص العتيبي",
      "work4":"كبير المحامين",
      "member5" :"راشد عوض العتيبي",
      "work5":"محام",
      "member6" :"عبدالعزيز عبدالله الموسى",
      "work6":"محام",
      "member7" :"مازن عمر باحكيم",
      "work7":"سكرتير قانوني",
      "see-more": "انظر المزيد",
      "Category List":"قائمة الفئات"
    }
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'ar',
    fallbackLng: 'ar',
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage']
    },
    interpolation: {
      escapeValue: false // React already escapes values
    }
  });

export default i18n;
