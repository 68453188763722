import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import axios from 'axios'

function Footer() {
    const {t, i18n} = useTranslation();
    const [email, setEmail] = useState("");

    const handleSubmit = (e)=>{
        e.preventDefault();
    }
    const [articles, setArticles]= useState([]);

    function getFirst(text) {
        const words = text.split(' '); 
        if (words.length <= 3) {
          return text;
        } else {
          return words.slice(0, 3).join(' ') + '...';
        }
      }

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get('https://admin.mithaqaltashrie.com.sa/api/articles');
            if (Array.isArray(response.data.articles.data)) {
              if(response.data.articles.data.length<=3){
                  setArticles(response.data.articles.data);
              }else{
                setArticles(response.data.articles.data.slice(0, 3));

              }
            } else {
              console.error('API response is not an array');
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        fetchData();
      }, []);
  return (
    <div className='bg-secondary py-14 relative'>
        <div className="hidden lg:block absolute opacity-20 bottom-0 w-full px-4 z-0">
            <div className='flex justify-center w-2/3 mx-auto'>
                <img src="/images/logo-1.png" className='w-44 mx-auto' alt="" />
                <img src="/images/logo-1.png" className='w-44 mx-auto' alt="" />
            </div>
        </div>
        <div className="absolute bottom-2.5 w-full z-0">
            <div className='flex justify-center items-center gap-x-2.5 text-white'>
                <p>{t("copyright-2024")}</p>
                <p className='text-xl'>©</p>
                <p>{t("rights-reserved")}</p>
            </div>
        </div>
        <div className="relative grid grid-cols-2 lg:grid-cols-3 gap-10 md:gap-20 xl:gap-40  px-4 lg:px-28 z-50">
            <div className='col-span-2 lg:col-span-1 mx-auto lg:mx-0 flex flex-col justify-center gap-y-4 order-3 lg:order-1'>
                <p className='text-white font-light'>{t('join-newsletter')} </p>
                <form onSubmit={handleSubmit}>
                <div className="flex flex-row justify-center w-full flex-nowrap">
                    
                        <input 
                            id="email"
                            name="email"
                            type="email" 
                            placeholder={t("Email")}
                            className='w-60 md:w-60 xl:w-60 bg-secondary text-white border border-blue-light rounded-full px-5 py-2 placeholder:text-blue-light placeholder:font-light' 
                            value = {email}
                            onChange={(e) =>setEmail(e.target.value)}
                        />
                        <input type="submit" value={t('join')} className={`bg-white border border-white text-secondary rounded-full w-16 text-center py-2 cursor-pointer hover:bg-gold ${i18n.language==="ar"?'mr-2':'ml-2'}`} />
                    
                </div>
                </form>
                <div className="flex justify-center gap-x-2.5">
                    <a href='https://www.linkedin.com/in/mithaq-altashrie-47693b324' target="_blank">
                    
                    <img src='/images/icons/LinkedIn.png' className='w-[30px] rounded'/>
                    </a>
                    <a href='https://x.com/MAltashrie' target='_blank'>
                    
                    <img src='/images/icons/TwitterX.png' className='w-[30px] rounded'/>
                    </a>
                    <a href='https://www.instagram.com/mithaqaltashrie4/' target='_blank'>
                    
                    <img src='/images/icons/Instagram.png' className='w-[30px]'/>
                    </a>
                    <a href='https://www.facebook.com/profile.php?id=61565064291702/' target='_blank'>
                    <img src='/images/icons/Facebook.png' className='w-[30px]'/>
                    </a>
                </div>
            </div>
            <div className='text-white flex flex-col gap-y-4 mx-auto order-1 lg:order-2'>
                <p className='text-xl font-semibold mb-4 uppercase'>{t("Quick Links")}</p>
                <a href="/">{t("home")}</a>
                <Link to={"/services"}>{t("our-services")}</Link>
                <Link to={"/contact"}>{t("contact-us")}</Link>
                <Link to={"/about"}>{t("about-us")}</Link>
            </div>
            <div className='text-white flex flex-col gap-y-4 mx-auto order-2 lg:order-3'>
                <p className='text-xl font-semibold mb-4 uppercase'>{t("Recent News")}</p>
                {
                    articles.map((article)=>(
                        <Link key={article.id} title={article.title} to={"/article/"+article.id} className='flex gap-x-1 items-center'>
                            {i18n.language==='ar'
                            ? (<ChevronLeftIcon className='text-primary w-5 h-5' />)
                            : (<ChevronRightIcon className='text-primary w-5 h-5' />)
                            }
                            <p>{getFirst(article.title)}</p>
                        </Link>
                    ))
                }
            </div>
        </div>
    </div>
  )
}

export default Footer