
import ContactForm from "../components/ContactForm";
import ContactInfo from "../components/ContactInfo";
import Footer from "../components/footer";
import Menu from "../components/menu";

function Contact() {
    return (
    <>
    <Menu/>
        <div className="mx-auto px-4 md:px-0 md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl" >
            <div className="grid md:grid-cols-2 gap-4">
                <div className="my-auto"> 
                    <ContactForm/>
                </div>
                <div className="m-6">
                    <img src="/images/contact.png"/>
                </div>
                </div>
            </div>
            <ContactInfo/>
       
            <div className="w-100"><iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=3795%20%D8%B4%D8%A7%D8%B1%D8%B9%20%D8%A7%D9%84%D8%A3%D9%85%D9%8A%D8%B1%20%D8%B9%D8%A8%D8%AF%D8%A7%D9%84%D8%B9%D8%B2%D9%8A%D8%B2%20%D8%A8%D9%86%20%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%20%D8%A8%D9%86%20%D8%AC%D9%84%D9%88%D9%8A%D8%8C%20RHOA7514%D8%8C%207514%D8%8C%20%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6%2012221%D8%8C%20%D8%A7%D9%84%D9%85%D9%85%D9%84%D9%83%D8%A9%20%D8%A7%D9%84%D8%B9%D8%B1%D8%A8%D9%8A%D8%A9%20%D8%A7%D9%84%D8%B3%D8%B9%D9%88%D8%AF%D9%8A%D8%A9+(%D8%B4%D8%B1%D9%83%D8%A9%20%D9%85%D9%8A%D8%AB%D8%A7%D9%82%20%D8%A7%D9%84%D8%AA%D8%B4%D8%B1%D9%8A%D8%B9%20%D9%84%D9%84%D9%85%D8%AD%D8%A7%D9%85%D8%A7%D8%A9)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe></div>
            
        <Footer/>
    </>
    );
}
export default Contact;