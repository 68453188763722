import React, { useState } from 'react'
import Menu from '../components/menu'
import Footer from '../components/footer'
import { CheckIcon } from '@heroicons/react/24/outline'
import Contact from '../components/about/contact'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'


function Service() {
    const {t} = useTranslation();
    const services = [
        {
            id:"1",
            title : "bankruptcy",
            subTitle : "bankruptcy-subtitle",
            image : "/images/services/service-0.jpg",
            content :"bankruptcy-description"
        },
        {
            id:"2",
            title : "financial-claims-title",
            subTitle : "financial-claim-subtitle",
            image : "/images/services/service-1.jpg",
            content :"finantial-claim-description"
        },
        {
            id:"3",
            title : "commercial-arbitration-title",
            subTitle : "commercial-arbitration-subtitle",
            image : "/images/services/service-2.jpg",
            content :"commercial-arbitration-description"
        },
        {
            id:"4",
            title : "drafting-and-reviewing",
            subTitle : "darfting-subtitle",
            image : "/images/services/service-3.jpg",
            content :"dtafting-description"
        },
        {
            id:"5",
            title : "debt-collection",
            subTitle : "debt-subtitle",
            image : "/images/services/service-15.jpg",
            content :"debt-description"
        },
        {
            id:"6",
            title : "civil-cases",
            subTitle : "civil-cases-subtitle",
            image : "/images/services/service-4.jpg",
            content :"civil-cases-description"
        },
        {
            id:"7",
            title : "legal-risk-analysis",
            subTitle : "risk-analysis-subtitle",
            image : "/images/services/service-5.jpg",
            content :"risk-analysis-description"
        },
        {
            id:"8",
            title : "memorandums-and-regulations",
            subTitle : "memorandums-subtitle",
            image : "/images/services/service-6.jpg",
            content :"memorandums-description"
        },
        {
            id:"9",
            title : "documentation",
            subTitle : "doc_sub",
            image : "/images/services/service-7.jpg",
            content :"doc-desc"
        },
        {
            id:"10",
            title : "legal-consultations",
            subTitle : "consultation-subtitle",
            image : "/images/services/service-8.jpg",
            content :"consultation-description"
        },
        {
            id:"11",
            title : "corporate-governance",
            subTitle : "corporate-governance-subtitle",
            image : "/images/services/service-9.jpg",
            content :"corporate-governance-description"
        }
    ]
    const { id } = useParams()
    const service = services.find(s=> s.id===id)

  return (
    <div>
        <Menu/>
        <div className="w-full bg-white py-16 mx-auto px-8 md:px-0 md:max-w-screen-md lg:max-w-screen-lg 2xl:max-w-screen-xl">
            <p className="text-5xl font-semibold text-secondary capitalize">{t(service.title)}</p>
            <p className="text-3xl text-secondary mt-3 mb-8">
                {t(service.subTitle)}
            </p>
            {/* <p className="my-8 text-lg text-secondary text-justify">
                {t(service.content)}
            </p> */}
            <img src={service.image} className='w-full rounded-2xl' alt="" />
            <p className="my-8 text-lg text-secondary text-justify">
                {t(service.content)}
            </p>
            <div className="grid md:grid-cols-2 gap-8">
                {/* <div>
                    <p className='text-2xl text-black font-medium mb-7'>
                       {t("What will you get")}
                    </p>
                    <div className="flex flex-col gap-y-4">
                        <div className="flex gap-x-3">
                            <CheckIcon className='text-primary-light w-6 h-6' />
                            <p className="text-black">Lorem ipsum dolor sit amet, consectetur adipiscing</p>
                        </div>
                        <div className="flex gap-x-3">
                            <CheckIcon className='text-primary-light w-6 h-6' />
                            <p className="text-black">Lorem ipsum dolor sit amet, consectetur adipiscing</p>
                        </div>
                        <div className="flex gap-x-3">
                            <CheckIcon className='text-primary-light w-6 h-6' />
                            <p className="text-black">Lorem ipsum dolor sit amet, consectetur adipiscing</p>
                        </div>
                        <div className="flex gap-x-3">
                            <CheckIcon className='text-primary-light w-6 h-6' />
                            <p className="text-black">Lorem ipsum dolor sit amet, consectetur adipiscing</p>
                        </div>
                    </div>
                </div> */}
                <div className='mx-auto'>
                    <p className='text-2xl text-black font-medium mb-7'>
                        {t("How does it work")}
                    </p>
                    <div className="flex flex-col gap-y-4">
                        <div className="flex gap-x-3">
                            <CheckIcon className='text-primary-light w-6 h-6' />
                            <p className="text-black">{t("Comprehensive Legal Counse")}</p>
                        </div>
                        <div className="flex gap-x-3">
                            <CheckIcon className='text-primary-light w-6 h-6' />
                            <p className="text-black">{t("Dispute Resolution")}</p>
                        </div>
                        <div className="flex gap-x-3">
                            <CheckIcon className='text-primary-light w-6 h-6' />
                            <p className="text-black">{t("Document Drafting and Review")}</p>
                        </div>
                        <div className="flex gap-x-3">
                            <CheckIcon className='text-primary-light w-6 h-6' />
                            <p className="text-black">{t("Litigation and Representation")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Contact/>
        <Footer/>
    </div>
  )
}

export default Service