import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';
function Team2(){
    const settings = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1
      };
      const {t, i18n} = useTranslation()
      const slides = [
        {
            image: '/images/team-member.png',
            text1: 'member1',
            text2: 'work1'
        },
        {
            image: '/images/team-member.png',
            text1: 'member2',
            text2: 'work2'
        },
        {
            image: '/images/team-member.png',
            text1: 'member3',
            text2: 'work3'
        },
        {
            image: '/images/team-member.png',
            text1: 'member4',
            text2: 'work4'
        },
        {
            image: '/images/team-member.png',
            text1: 'member5',
            text2: 'work5'
        },
        {
            image: '/images/team-member.png',
            text1: 'member6',
            text2: 'work6'
        },
        {
            image: '/images/team-member.png',
            text1: 'member7',
            text2: 'work7'
        },
      ]
    return(
        <div className='bg-secondary p-10  md:grid md:grid-cols-2 gap-8 relative'>
        <div className="absolute z-0 right-0 top-0">
            <img src="/images/overlay-2.png" className='w-[490px] opacity-35' alt="" />
        </div>
        <img src="/images/team.jpg" className='w-full rounded-2xl' alt="" />
        <div className="mt-16 md:mt-0 lg:pr-20 flex flex-col justify-center gap-y-4">
            <div className="flex w-full justify-between items-center flex-wrap gap-y-4 md:gap-y-0">
                <p className='text-white text-3xl font-medium'>{t("meet-our-team")}</p>
               
            </div>
            <p className="mt-5 text-white w-full text-justify">
                {t("our-team-description")}
            </p>
            <Slider {...settings}>
            {slides.map((slide, index) => (
                    <div key={index} className="relative">
                        <img src={slide.image} alt={`Slide ${index + 1}`} className="w-full h-auto" />
                        <div className="absolute bottom-0 left-0 right-0 text-center p-4 text-white">
                            <h2 className="text-xl">{t(slide.text1)}</h2>
                            <p>{t(slide.text2)}</p>
                        </div>
                    </div>
                ))}
            </Slider>
       </div>
       </div>
    )
}
export default Team2;